import axios from '../http/axios'
import { generateParams } from '../helpers/helper'

export default {
  async getGiftCardTemplateSectionDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)
    return await axios.get('gift-card-template-sections/datatable', {
      params: serverQueryObj
    })
  },
    async addGiftCardTemplateSection (payload) {
        return await axios.post('gift-card-template-sections', payload)
    },

    async updateGiftCardTemplateSection (id, payload) {
        return await axios.patch(`gift-card-template-sections/${id}`, payload)
    },

    async deleteGiftCardTemplateSection (id) {
        return await axios.delete(`gift-card-template-sections/${id}`)
    },

    async getGiftCardTemplateSection (id) {
        return await axios.get(`gift-card-template-sections/${id}`)
    },

    async getGiftCardTemplate () {
      return await axios.get('gift-card-templates')
    },

    async dropdownListGiftCardTemplateSection () {
      return await axios.get('gift-card-template-sections/list/dropdown')
  }
}
