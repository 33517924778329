import axios from '../http/axios'
import {generateParams} from '../helpers/helper'

export default {
  async getPaymentRequestDatatable (filterSortPage, filter, status) {
    const serverQueryObj = generateParams(filterSortPage, filter)
    return await axios.get('withdraw/datatable', {
      params: {
        ...serverQueryObj,
        'status': status
      }
    })
  },
  async getPendingPaymentCount() {
    return await axios.get(`withdraw/paymentRequest/count`);
  },
  async updatePaymentRequestStatus (payload) {
    return await axios.patch(`withdraw/${payload.withdraw_id}/change-status`, payload)
  },
  async updateBulkPaymentRequestStatus (payload) {
    return await axios.patch(`withdraw/bulk-change-status`, payload)
  }
}
