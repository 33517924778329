import axios from '../http/axios'
import {generateParams} from '../helpers/helper'

export default {
  async getGiftCardCategories () {
    return await axios.get('gift-card-categories')
  },

  async getGiftCardCategoryDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)
    return await axios.get('gift-card-categories/datatable', {
      params: serverQueryObj
    })
  },

  async createGiftCardCategory (giftCardCategory) {
    return await axios.post('gift-card-categories', giftCardCategory)
  },

  async updateGiftCardCategory (giftCardCategory) {
    return await axios.put(`gift-card-categories/${giftCardCategory.gift_card_category_id}`, giftCardCategory)
  },

  async deleteGiftCardCategory (giftCardCategoryId) {
    return await axios.delete(`gift-card-categories/${giftCardCategoryId}`)
  }
}
