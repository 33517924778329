import axios from "../http/axios"
import { generateParams } from "../helpers/helper"

export default {
    async getStoryDatatable(filterSortPage, filter) {
        const serverQueryObj = generateParams(filterSortPage, filter)
        return await axios.get("story/datatable", {
            params: serverQueryObj,
        })
    },
    async addStory(payload) {
        return await axios.post("story", payload)
    },

    async updateStory(id, payload) {
        return await axios.put(`story/${id}`, payload)
    },

    async deleteStory(id) {
        return await axios.delete(`story/${id}`)
    },

    async getStory(id) {
        return await axios.get(`story/${id}`)
    },
}
