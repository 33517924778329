export default {
  SET_REFERRAL (state, paymentRequests) {
    state.referrals = paymentRequests
  },

  SET_REFEREE_DETAIL (state, paymentRequests) {
    state.referralDetails = paymentRequests
  },

  // DELETE_PAYMENT_REQUEST (state, withdrawId) {
  //   state.paymentRequests.data = state.paymentRequests.data.filter((s) => (s.withdraw_id != withdrawId))
  //   state.paymentRequests.meta.total = state.paymentRequests.meta.total - 1
  // },

  CLEAR_REFERRAL (state) {
    state.referrals.data = []
    state.referrals.links = {}
    state.referrals.meta = {}
  },

  CLEAR_REFEREE_DETAIL (state) {
    state.referralDetails = {
      data: [],
      links: {},
      meta: {}
    }
  },
}
