import axios from '../http/axios/'
import {generateParams} from '../helpers/helper'

export default {
  async createBanner (banner) {
    return await axios.post('banner', banner)
  },

  async getBannerDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)
    return await axios.get('banner/datatable', {
      params: serverQueryObj
    })
  },

  async updateBanner (banner) {
    return await axios.put(`banner/${banner.id}`, banner)
  },

  async getBannerList () {
    return await axios.get('banner', {})
  },

  async deleteBanner (bannerId) {
    return await axios.delete(`banner/${bannerId}`)
  }
}
