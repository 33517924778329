
import giveAwayService from '../../services/GiveAwayService'

export default {
  geGiveAway (_, giveAwayId) {
    return giveAwayService.getGiveAway(giveAwayId)
      .then(({data}) => {
        return data
      })
  },

  getGiveAwayDatatable ({commit}, {eventForServer, filter}) {
    return giveAwayService.getGiveAwayDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_GIVE_AWAY', data.payload)
        return data
      })
  },

    createGiveAway ({commit}, payload) {
        return giveAwayService.createGiveAway(payload)
            .then(({data}) => {
                // commit('UPDATE_GIFT_CARD_PRODUCT', data.payload)
                return data
            })
    },

  updateGiveAway ({commit}, { giveAwayId, payload }) {
    return giveAwayService.updateGiveAway(giveAwayId, payload)
      .then(({data}) => {
        // commit('UPDATE_GIFT_CARD_PRODUCT', data.payload)
        return data
      })
  },

    deleteGiveAway ({commit}, giveAwayId) {
        return giveAwayService.deleteGiveAway(giveAwayId)
            .then(({data}) => {
                // commit('UPDATE_GIFT_CARD_PRODUCT', data.payload)
                return data
            })
    }
}
