export default {

  SET_DASHBOARD_STATS (state, dashboardStats) {
    state.dashboardStats = dashboardStats
  },

  SET_AVERAGE_SPENT (state, averageSpent) {
    state.averageSpent = averageSpent
  },

  SET_DOWNLOAD_CHART (state, downloadChart) {
    state.downloadChart = downloadChart
  },

  SET_LINKED_ACCOUNT_CHART (state, linkedAccountChart) {
    state.linkedAccountChart = linkedAccountChart
  },

  SET_TRANSACTION_CHART (state, transactionChart) {
    state.transactionChart = transactionChart
  },

  SET_GIFT_CARD_TRANSACTION_CHART (state, giftCardTransactionChart) {
    state.giftCardTransactionChart = giftCardTransactionChart
  }
}
