import axios from '../http/axios'
import {generateParams} from '../helpers/helper'

export default {
  async getGiftCardProductDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)
    return await axios.get('gift-card-products/datatable', {
      params: serverQueryObj
    })
  },

  async getGiftCardProduct (id) {
    return await axios.get(`gift-card-products/${id}`)
  },

    async createGiftCardProduct (payload) {
        return await axios.post('gift-card-products', payload)
    },

  async updateGiftCardProduct (id, payload) {
    return await axios.put(`gift-card-products/${id}`, payload)
  },

    async deleteGiftCardProduct (id) {
        return await axios.delete(`gift-card-products/${id}`)
    },

    async dropdownListGiftCardProducts () {
        return await axios.get('gift-card-products/list/dropdown')
    }
}
