
import transaction from '../../services/TransactionService'

export default {
  getTransactionDatatable ({ commit }, { eventForServer, filter }) {
    return transaction.getTransactionDatatable(eventForServer, filter)
      .then((response) => {
        if (response.status === 200 && filter.toDownload) {
          const blob = new Blob([response.data], { type: 'text/csv' })

          // Create a download link for the Blob
          const url = window.URL.createObjectURL(blob)

          // Create an anchor element to trigger the download
          const a = document.createElement('a')
          a.href = url
          a.download = `Transactions-${Date.now()}.csv` // Specify the desired file name
          document.body.appendChild(a)

          // Trigger the download
          a.click()

          // Clean up resources
          window.URL.revokeObjectURL(url)
          // if we are calling api for export then we will not update the state
          return response.data
        }
        commit('SET_TRANSACTION', response.data.payload)
        return response.data
      })
  }
}
