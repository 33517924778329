import axios from '../http/axios/'
import {generateParams} from '../helpers/helper'

export default {
  async createCategory (category) {
    return await axios.post('category', category)
  },

  async getCategoryDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)
    return await axios.get('category/datatable', {
      params: serverQueryObj
    })
  },

  async updateCategory (category) {
    return await axios.put(`category/${category.category_id}`, category)
  },

  async getCategoryList () {
    return await axios.get('category', {})
  },

  async deleteCategory (categoryId) {
    return await axios.delete(`category/${categoryId}`)
  }
}
