import axios from '../http/axios/'
import {generateParams} from '../helpers/helper'

export default {
  async createPost (post) {
    return await axios.post('post/admin/add', post)
  },

  async getPostDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)
    return await axios.get('post/datatable', {
      params: serverQueryObj
    })
  },

  async updatePost (post) {
    return await axios.put(`post/${post.id}`, post)
  },

  async getPostList () {
    return await axios.get('post', {})
  },

  async deletePost (postId) {
    return await axios.delete(`post/${postId}`)
  }
}
