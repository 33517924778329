
import actions from './modulePaymentRequestActions'
import getters from './modulePaymentRequestGetters'
import mutations from './modulePaymentRequestMutations'
import state from './modulePaymentRequestState'

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
