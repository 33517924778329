
import actions from './moduleGiveAwayActions'
import mutations from './moduleGiveAwayMutations'
import state from './moduleGiveAwayState'
import getters from './moduleGiveAwayGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
