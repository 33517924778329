import axios from '../../http/axios'

/**
 * Merchant Dashboard
 * global params
 */
const merchantDashboardGlobalParams = {
  setMerchantDashboardSetDateRange ({commit}, payload) {
    commit('SET_MERCHANT_DASHBOARD_SELECTED_DATE_RANGE', payload)
  },

  resetMerchantDashboardSetDateRange ({commit}) {
    commit('UNSET_MERCHANT_DASHBOARD_SELECTED_DATE_RANGE')
  }
}

export default {
  ...merchantDashboardGlobalParams,

  changePassword (context, payload) {
    return axios.put('admin/changepassword', payload)
  },

  getUser ({}, userId) {
    return axios.get(`users/${userId}`).then(({ data }) => data)
  },
}
