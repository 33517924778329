export default {
  Auth: {
    identityPoolId: 'eu-west-2:d83b72f1-cccb-44e1-8325-bb7cd614cb49', //REQUIRED - Amazon Cognito Identity Pool ID
    region: 'eu-west-2', // REQUIRED - Amazon Cognito Region
    userPoolId: 'eu-west-2_cSkagggah', //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: 'XX-XXXX-X_abcd1234' //OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
      bucket: 'amplifytuck16cb4bd3d97a4bff88c3eab4778e6230105049-tuckenv', //REQUIRED -  Amazon S3 bucket name
      region: 'eu-west-2' //OPTIONAL -  Amazon service region
    }
  }
}
