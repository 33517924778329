import axios from '../http/axios/'
import {generateParams} from '../helpers/helper'

export default {
  // async createRegion (category) {
  //   return await axios.post('category', category)
  // },
  //
  // async getRegionDatatable (filterSortPage, filter) {
  //   const serverQueryObj = generateParams(filterSortPage, filter)
  //   return await axios.get('category/datatable', {
  //     params: serverQueryObj
  //   })
  // },
  //
  // async updateRegion (category) {
  //   return await axios.put(`category/${category.category_id}`, category)
  // },

  async getRegionList () {
    let config = {
      headers: {
        'X-API-TOKEN': 'hq8D6qAnmGWZDQddYC5kTqYzh5rq2LY6CAp8Cy7fRe47m',
      }
    }
    return await axios.get('regions', config)
  },

  // async deleteRegion (categoryId) {
  //   return await axios.delete(`category/${categoryId}`)
  // }
}
