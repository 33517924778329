import twoFA from "../../services/2FAService";

export default {
    enable2FARequest() {
        return twoFA.twoFAEnableRequest().then(({ data }) => data.payload);
    },

    async enable2FA({ commit }, payload) {
        try {
            await twoFA.twoFAEnable(payload);
            commit("auth/TWO_FA_ENABLED", null, { root: true });
        } catch (e) {
            throw new Error(e.response.data.message);
        }
    },

    async validate2FA({ commit }, payload) {
        // console.log("I am called");
        try {
            await twoFA.validate2FA(payload);
            commit("auth/TWO_FA_VALIDATED", null, { root: true });
            commit("VALIDATED_2FA_MUTATION");

            await localStorage.setItem("two_fa_validated", 1);
        } catch (e) {
            throw new Error(e.response.data.message);
        }
    }
};
