export default {

  CREATE_CATEGORY (state, category) {
    state.category.data.unshift(category)
  },

  SET_CATEGORY (state, categories) {
    state.category = categories
  },

  UPDATE_CATEGORY (state, category) {
    state.category.data = state.category.data.map((s) => {
      return (s.category_id == category.category_id) ? (s = category) : s
    })
  },

  DELETE_CATEGORY (state, categoryId) {
    state.category.data = state.category.data.filter((s) => (s.category_id != categoryId))
    state.category.meta.total = state.category.meta.total - 1
  }

}
