
import actions from './moduleSendNotificationActions'
import getters from './moduleSendNotificationGetters'
import mutations from './moduleSendNotificationMutations'
import state from './moduleSendNotificationState'

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations
}

