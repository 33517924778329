import axios from '../http/axios'
import { generateParams } from '../helpers/helper'

export default {
  async getPushNotificationsForMerchantDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('push-notifications', {
      params: serverQueryObj
    })
  },

  async sendPushNotificationRequest (payload) {
    return await axios.post('push-notifications', payload)
  },

  async changeStatusOfPushNotificationRequest (payload) {
    console.log(payload)
    return await axios.patch(`push-notifications/${payload.pushNotificationId}/status-change`, payload)
  }
}
