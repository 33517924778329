
import actions from './moduleBankStatementActions'
import getters from './moduleBankStatementGetters'
import mutations from './moduleBankStatementMutations'
import state from './moduleBankStatementState'

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
