
import actions from './moduleLoyaltyCardProductActions'
import mutations from './moduleLoyaltyCardProductMutations'
import state from './moduleLoyaltyCardProductState'
import getters from './moduleLoyaltyCardProductGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
