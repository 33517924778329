
import actions from './moduleGiftCardProductActions'
import mutations from './moduleGiftCardProductMutations'
import state from './moduleGiftCardProductState'
import getters from './moduleGiftCardProductGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
