import axios from "../../http/axios";
import qs from "querystring";

export default {
    async login({ commit }, payload) {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            const loginData = {
                email: payload.email,
                password: payload.password
            };

            const response = await axios.post(
                "admin/login",
                qs.stringify(loginData),
                config
            );
            commit("LOGIN_USER", response.data.payload);
            localStorage.setItem(
                "accessToken",
                response.data.payload.access_token
            );
            return response.data.payload;
        } catch (e) {
            console.log(e.response);
            throw new Error(e.response.data.message);
        }
    },

    async getLoggedInUserProfile({ commit }) {
        const response = await axios.get("user/me");
        commit("FETCH_USER", response.data.payload);
        if (localStorage.getItem("two_fa_validated") == "1") {
            commit("2fa/VALIDATED_2FA_MUTATION", null, { root: true });
        }

        return response.data.payload;
    },

    async changeProfileImage ({ commit }, payload) {
      return await axios
        .put('admin/change-profile-picture', payload)
        .then(({data}) => {
          commit('SET_USER', data.payload)
        });
    },

    async logoutLocally({ commit }) {
        commit("LOGOUT_USER");
        commit("2fa/LOGOUT_USER", null, { root: true });
    },

    async logout({ commit }) {
        await axios.get("logout");
        commit("LOGOUT_USER");
        commit("2fa/LOGOUT_USER", null, { root: true });
    },

    async forgotPassword({ commit }, payload) {
      return await axios.post('forgot-password', payload);
    }
};
