
import actions from './moduleGiftCardTransactionActions'
import getters from './moduleGiftCardTransactionGetters'
import mutations from './moduleGiftCardTransactionMutations'
import state from './moduleGiftCardTransactionState'

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
