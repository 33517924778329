
import actions from './moduleRevokedAccountActions'
import getters from './moduleRevokedAccountGetters'
import mutations from './moduleRevokedAccountMutations'
import state from './moduleRevokedAccountState'

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
