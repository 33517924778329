import axios from '../http/axios/'

export default {
  async getDashboardStats (start_date, end_date) {
    return await axios.get('report/dashboard-stats', {
      params: {start_date, end_date}
    })
  },

  async getAverageSpent (start_date, end_date) {
    return await axios.get('report/average-spent-stats', {
      params: {start_date, end_date}
    })
  },

  async getDownloadChart (start_date, end_date, report_type) {
    return await axios.get('report/downloads-chart', {
      params: {start_date, end_date, report_type}
    })
  },

  async getLinkedAccountChart (start_date, end_date, report_type) {
    return await axios.get('report/linkedaccount-chart', {
      params: {start_date, end_date, report_type}
    })
  },

  async getTransactionChart (start_date, end_date, report_type) {
    return await axios.get('report/transaction-chart', {
      params: {start_date, end_date, report_type}
    })
  },

  async getGiftCardTransactionChart (start_date, end_date, report_type) {
    return await axios.get('report/gift-card-transaction-chart', {
      params: {start_date, end_date, report_type}
    })
  },
}
