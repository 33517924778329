import referral from '../../services/ReferralService'

export default {
  getReferralDatatable ({commit}, {eventForServer, filter, status}) {
    return referral.getReferralDatatable(eventForServer, filter, status)
      .then(({data}) => {
        if(filter.toDownload){
          // if we are calling api for export then we will not update the state
          return data;
        }
        commit('SET_REFERRAL', data.payload)
        return data
      })
  },

  getReferralDetailDatatable ({ commit }, { eventForServer, filter, userId }) {
    return referral.getReferralDetailDatatable(eventForServer, filter, userId)
      .then(({ data }) => {
        commit("SET_REFEREE_DETAIL", data.payload)

        return data
      })
  },

  // updatePaymentRequestStatus ({commit}, payload) {
  //   return paymentRequest.updatePaymentRequestStatus(payload)
  //     .then(({data}) => {
  //       commit('DELETE_PAYMENT_REQUEST', payload.withdraw_id)
  //       return data
  //     })
  // },

  clearReferralData ({commit}) {
    commit('CLEAR_REFERRAL')
  }
}
