import bankStatement from '../../services/BankStatementService'

export default {
  getBankStatementDatatable ({commit}, {eventForServer, filter}) {
    return bankStatement.getBankStatementDatatable(eventForServer, filter)
      .then(({data}) => {

        commit('SET_BANK_STATEMENT', data.payload)
        return data
      })
  },

  exportBankStatement ({commit}, payload) {
    return bankStatement.exportBankStatement(payload)
      .then(({data}) => {
        return data
      })
  },

  clearBankStatementData ({commit}) {
    commit('CLEAR_BANK_STATEMENT')
  },




}
