
import actions from './moduleTransactionActions'
import getters from './moduleTransactionGetters'
import mutations from './moduleTransactionMutations'
import state from './moduleTransactionState'

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
