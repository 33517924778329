import axios from "../http/axios/"
import { generateParams } from "@/helpers/helper"

export default {
    async getDownloadDatatable(filterSortPage, filter) {
        const serverQueryObj = generateParams(filterSortPage, filter)

        return await axios.get("/downloads/datatable", {
            params: serverQueryObj,
        })
    },
    async getDownload(id) {
        return await axios.get(`/download/${id}`)
    },
    async updateDownload(id, payload) {
        return await axios.put(`download/${id}`, payload)
    },
}
