
import giftCardSectionService from '../../services/GiftCardSectionService'

export default {
  getGiftCardSectionDatatable ({commit}, {eventForServer, filter}) {
    return giftCardSectionService.getGiftCardSectionDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_GIFT_CARD_SECTIONS', data.payload)
        return data
      })
  },
    addGiftCardSection (_, payload) {
      return giftCardSectionService.addGiftCardSection(payload)
        .then(({data}) => {
          return data
        })
    },

    updateGiftCardSection (_, { id, payload }) {
        return giftCardSectionService.updateGiftCardSection(id, payload)
            .then(({data}) => {
                return data
            })
    },

    deleteGiftCardSection (_, id) {
        return giftCardSectionService.deleteGiftCardSection(id)
            .then(({data}) => {
                return data
            })
    },

    getGiftCardSection (_, id) {
        return giftCardSectionService.getGiftCardSection(id)
            .then(({data}) => {
                return data
            })
    },

    dropdownListGiftCardSections ({commit}) {
      return giftCardSectionService.dropdownListGiftCardSections()
          .then(({data}) => {
              // commit('UPDATE_GIFT_CARD_PRODUCT', data.payload)
              return data
          })
  }
}
