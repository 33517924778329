
import actions from './moduleReferralActions'
import getters from './moduleReferralGetters'
import mutations from './moduleReferralMutations'
import state from './moduleReferralState'

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
