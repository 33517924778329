import axios from '../http/axios'
import {generateParams} from '../helpers/helper'

export default {
  async getGiveAwayDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)
    return await axios.get('give-aways/datatable', {
      params: serverQueryObj
    })
  },

  async getGiveAway (id) {
    return await axios.get(`give-aways/${id}`)
  },

    async createGiveAway (payload) {
        return await axios.post('give-aways', payload)
    },

  async updateGiveAway (id, payload) {
    return await axios.put(`give-aways/${id}`, payload)
  },

    async deleteGiveAway (id) {
        return await axios.delete(`give-aways/${id}`)
    }
}
