
import actions from './modulePostActions'
import mutations from './modulePostMutations'
import state from './modulePostState'
import getters from './modulePostGetters'



export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
