export default {
  idle_user_time: 1200, // value in seconds. ex. 60 x 20 = 1200 sec (20 min)

  roles: {
    admin: 'admin',
    merchant: 'merchant'
  },

  push_notification_for_merchant: {
    status: {
      under_review: 'under_review',
      approved: 'approved',
      rejected: 'rejected'
    }
  },

  S3Folders: {
    adminProfile: 'users/profile',
    invoices: 'invoices',
    push_notification_excel: 'push_notification_excel',
    giftCardProduct: 'gift-card-product',
    AppConfig: 'app-config',
  }
}
