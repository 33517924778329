import actions from "./module2FAAction";
import getters from "./module2FAGetters";
import mutations from "./module2FAMutation";
import state from "./module2FAState";

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
};
