
import actions from './moduleGiftCardSectionActions'
import mutations from './moduleGiftCardSectionMutations'
import state from './moduleGiftCardSectionState'
import getters from './moduleGiftCardSectionGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
