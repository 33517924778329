export default {

  SET_GIFT_CARD_PRODUCT (state, giftCardProduct) {
    state.giftCardProduct = giftCardProduct
  },

  UPDATE_GIFT_CARD_PRODUCT (state, giftCardProduct) {
    state.giftCardProduct.data = state.giftCardProduct.data.map((s) => {
      return (s.id == giftCardProduct.id) ? (s = giftCardProduct) : s
    })
  },
}
