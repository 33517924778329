import merchant from '../../services/MerchantService'
import category from '../../services/CategoryService'

export default {
  createMerchant ({commit}, payload) {
    return merchant.createMerchant(payload)
      .then(({data}) => {
        commit('CREATE_MERCHANT', data.payload)
        return data
      })
  },

  getMerchantDatatable ({commit}, {eventForServer, filter}) {
    return merchant.getMerchantDatatable(eventForServer, filter)
      .then(({data}) => {
        if (filter.toDownload) {
          // if we are calling api for export then we will not update the state
          return data
        }
        commit('SET_MERCHANT', data.payload)
        return data
      })
  },

  getSingleMerchant ({commit}, payload) {
    return merchant.getSingleMerchant(payload.merchant_id)
      .then(({data}) => {
        return data.payload
      })
  },

  updateMerchant ({commit}, payload) {
    return merchant.updateMerchant(payload)
      .then(({data}) => {
        commit('UPDATE_MERCHANT', data.payload)
        return data
      })
  },

  deleteMerchant ({commit}, merchantId) {
    return merchant.deleteMerchant(merchantId)
      .then(() => {
        commit('DELETE_MERCHANT', merchantId)
      })
  },
  async getAllMerchant () {
    const response =  await merchant.getAllMerchant()
    return response
  },
  async getNotHostListedMerchant () {
    const response =  await merchant.getNotHostListedMerchant()
    return response
  }
}
