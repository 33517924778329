import axios from '../http/axios'
import { generateParams } from '../helpers/helper'

export default {
  async getAppConfig() {
    return await axios.get('app-config')
  },

  async sendAppConfigRequest (payload) {
    return await axios.post('app-config', payload)
  },

}
