export default {
  SET_REVOKED_ACCOUNT (state, revokedAccounts) {
    state.revokedAccounts = revokedAccounts
  },

  CLEAR_REVOKED_ACCOUNT (state) {
    state.revokedAccounts.data = []
    state.revokedAccounts.links = {}
    state.revokedAccounts.meta = {}
  }


}
