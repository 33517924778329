
import actions from './moduleGiftCardTemplateSectionActions'
import mutations from './moduleGiftCardTemplateSectionMutations'
import state from './moduleGiftCardTemplateSectionState'
import getters from './moduleGiftCardTemplateSectionGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
