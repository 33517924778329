import giftCardTemplateSectionService from "../../services/GiftCardTemplateSectionService"
import giftCardProductService from "../../services/GiftCardProductService"

export default {
    getGiftCardTemplateSectionDatatable(
        { commit },
        { eventForServer, filter }
    ) {
        return giftCardTemplateSectionService
            .getGiftCardTemplateSectionDatatable(eventForServer, filter)
            .then(({ data }) => {
                commit("SET_GIFT_CARD_TEMPLATE_SECTIONS", data.payload)
                return data
            })
    },
    addGiftCardTemplateSection(_, payload) {
        return giftCardTemplateSectionService
            .addGiftCardTemplateSection(payload)
            .then(({ data }) => {
                return data
            })
    },

    updateGiftCardTemplateSection(_, { id, payload }) {
        return giftCardTemplateSectionService
            .updateGiftCardTemplateSection(id, payload)
            .then(({ data }) => {
                return data
            })
    },

    deleteGiftCardTemplateSection(_, id) {
        return giftCardTemplateSectionService
            .deleteGiftCardTemplateSection(id)
            .then(({ data }) => {
                return data
            })
    },

    getGiftCardTemplateSection(_, id) {
        return giftCardTemplateSectionService
            .getGiftCardTemplateSection(id)
            .then(({ data }) => {
                console.log(data)
                return data
            })
    },
    getGiftCardTemplate({ commit }) {
        return giftCardTemplateSectionService
            .getGiftCardTemplate()
            .then(({ data }) => {
                // commit('UPDATE_GIFT_CARD_PRODUCT', data.payload)
                return data
            })
    },
    dropdownListGiftCardTemplateSection({ commit }) {
        return giftCardTemplateSectionService
            .dropdownListGiftCardTemplateSection()
            .then(({ data }) => {
                // commit('UPDATE_GIFT_CARD_PRODUCT', data.payload)
                return data
            })
    },
}
