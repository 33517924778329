
import actions from './moduleInvoiceActions'
import mutations from './moduleInvoiceMutations'
import state from './moduleInvoiceState'
import getters from './moduleInvoiceGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
