
import dashboardService from '../../services/DashbaordService'

export default {

  getDashboardStats ({commit}, {start_date, end_date}) {
    return dashboardService.getDashboardStats(start_date, end_date)
      .then(({data}) => {
        commit('SET_DASHBOARD_STATS', data)
        return data
      })
  },

  getAverageSpent ({commit}, {start_date, end_date}) {
    return dashboardService.getAverageSpent(start_date, end_date)
      .then(({data}) => {
        commit('SET_AVERAGE_SPENT', data)
        return data
      })
  },

  getDownloadChart ({commit}, {start_date, end_date, report_type}) {
    return dashboardService.getDownloadChart(start_date, end_date, report_type)
      .then(({data}) => {
        commit('SET_DOWNLOAD_CHART', data)
        return data
      })
  },

  getLinkedAccountChart ({commit}, {start_date, end_date, report_type}) {
    return dashboardService.getLinkedAccountChart(start_date, end_date, report_type)
      .then(({data}) => {
        commit('SET_LINKED_ACCOUNT_CHART', data)
        return data
      })
  },

  getTransactionChart ({commit}, {start_date, end_date, report_type}) {
    return dashboardService.getTransactionChart(start_date, end_date, report_type)
      .then(({data}) => {
        commit('SET_TRANSACTION_CHART', data)
        return data
      })
  },

  getGiftCardTransactionChart ({commit}, {start_date, end_date, report_type}) {
    return dashboardService.getGiftCardTransactionChart(start_date, end_date, report_type)
      .then(({data}) => {
        commit('SET_GIFT_CARD_TRANSACTION_CHART', data)
        return data
      })
  },
}
