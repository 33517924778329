
export default {
  SET_TRANSACTION_VAULT (state, transactionVault) {
    state.transactionVault = transactionVault
  },
  UPDATE_TRANSACTION_VAULT (state, updatedTransactionVault) {
    state.transactionVault.data = state.transactionVault.data.map(individualTransactionVault => {
      if (individualTransactionVault.transaction_vault_id == updatedTransactionVault.transaction_vault_id) {
        return updatedTransactionVault
      } else {
        return individualTransactionVault
      }
    })
  }
}
