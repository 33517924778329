import { v4 as uuidv4 } from 'uuid'
import { Storage } from 'aws-amplify'
import dayjs from 'dayjs'

export const generateParams = (filterSortPage, filter) => {
  const serverQueryObj = {}

  if (filterSortPage !== null) {
    if (filterSortPage.hasOwnProperty('page')) {
      serverQueryObj.page = filterSortPage.page + 1
    }

    if (filterSortPage.hasOwnProperty('rows')) {
      serverQueryObj.rows = filterSortPage.rows
    }

    if (filterSortPage.sortField !== null) {
      serverQueryObj.sortField = filterSortPage.sortField
      serverQueryObj.sortOrder = filterSortPage.sortOrder === 1 ? 'asc' : 'desc'
    }
  }

  if (filter.global !== '') {
    serverQueryObj.globalSearch = filter.global
  }

  if (filter.startDate !== '') {
    serverQueryObj.start_date = filter.startDate
  } else if (filter.start_date !== '') {
    serverQueryObj.start_date = filter.start_date
  }

  if (filter.endDate !== '') {
    serverQueryObj.end_date = filter.endDate
  } else if (filter.end_date !== '') {
    serverQueryObj.end_date = filter.end_date
  }

  if (filter.toDownload) {
    serverQueryObj.to_download = 1;
  }

  if (filter.vault_status !== '') {
     serverQueryObj.vault_status = filter.vault_status
  }

  return serverQueryObj
}

export const formatDate = (date) => {
  return (new Date(date)).toISOString().split('T')[0]
}

/**
 * Get Default Date range for Date Picker
 */
export const getDateRangeForDatePicker = (difference = -1, unit = 'month', format = 'YYYY-MM-DD') => {
  const startDate = dayjs().add(difference, unit).format(format)
  const endDate = dayjs().format(format)

  return `${startDate} to ${endDate}`
}

// Format the Report Type Label
export const getFormattedReportType = (type = 'year') => {
  return {
    'day': 'daily',
    'week': 'weekly',
    'month': 'monthly',
    'year': 'yearly'
  }[type]
}

// GET UNIQUE FILE NAME
export const getUniqueFileNameByFile = (file) => {
  const extension = file.name.split('.').pop()

  return `${uuidv4()}.${extension}`
}

/*
 * AWS
 * Upload File to S3
 */
export const uploadFileToS3 = async (filePath, file, config = {}) => {
  return await Storage.put(filePath, file, {
    contentType: file.type,
    ...config
  })
}
