export default {

  CREATE_POST (state, post) {
    state.post.data.unshift(post)
  },

  SET_POST (state, posts) {
    state.post = posts
  },

  UPDATE_POST(state, post) {
    state.post.data = state.post.data.map((s) => {
      return (s.id == post.id) ? (s = post) : s
    })
  },

  DELETE_POST (state, postID) {
    state.post.data = state.post.data.filter((s) => (s.id != postID))
    state.post.meta.total = state.post.meta.total - 1
  }

}
