<template>
  <span>
    <img :src="iconSrc" :alt="icon" />
  </span>
</template>

<script>
export default {
  name: 'AppIcon',

  props: {
    icon: {
      type: String,
      require: true
    },
    iconPack: {
      type: String,
      require: true
    },
    iconType:{
      type: String,
      default: 'svg'
    }
  },

  data () {
    return {
      iconSrc: ''
    }
  },

  created () {
    const iconFolder = this.getIconFolder()
    const iconName = `${this.icon}.${this.iconType}`

    this.iconSrc = require(`@assets/images/icons/${iconFolder}/${iconName}`)
  },

  methods: {
    getIconFolder () {
      return {
        svg: 'svg'
      }[this.iconType]
    }
  }
}
</script>
