/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import moduleAuth from './auth/moduleAuth'
import moduleUser from './user/moduleUser'
import moduleCategory from './category/moduleCategory'
import moduleBanner from './banner/moduleBanner'
import moduleGiftCardCategory from './gift-card-category/moduleGiftCardCategory'
import moduleGiftCardProduct from './gift-card-product/moduleGiftCardProduct'
import moduleTag from './tag/moduleTag'
import moduleMerchant from './merchant/moduleMerchant'
import moduleSendNotification from './send-notification/moduleSendNotification'
import moduleTransaction from './transaction/moduleTransaction'
import modulePaymentRequest from './payment-request/modulePaymentRequest'
import moduleReferral from './referral/moduleReferral'
import moduleBankStatement from './bank-statement/moduleBankStatement'
import module2FA from './2fa/module2FA'
import moduleRevokedAccount from './revoked-account/moduleRevokedAccount'
import moduleTransactionVault from './transaction-vault/moduleTransactionVault'
import moduleDashboard from './dashboard/moduleDashboard'
import moduleDownload from './download/moduleDownload'
import moduleInvoice from './invoice/moduleInvoice'
import moduleSupport from './support/moduleSupport'
import moduleRegion from './region/moduleRegion'
import moduleHotspot from './hotspot/moduleHotspot'
import moduleLandingScreen from './landing-screen/moduleLandingScreen'
import moduleGiftCardTransaction from "./gift-card-transaction/moduleGiftCardTransaction";
import moduleAppConfig from "./app-config/moduleAppConfig";
import moduleGiveAway from "./give-away/moduleGiveAway";
import moduleGiftCardSection from "./gift-card-section/moduleGiftCardSection";
import moduleGiftCardTemplateSection from "./gift-card-template-section/moduleGiftCardTemplateSection";
import moduleStory from './story/moduleStory';
import moduleLoyaltyCardProduct from './loyalty-card-product/moduleLoyaltyCardProduct';
import modulePost from './post/modulePost'

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    'auth': moduleAuth,
    'user': moduleUser,
    'category': moduleCategory,
    'banner': moduleBanner,
    'gift-card-category': moduleGiftCardCategory,
    'gift-card-product': moduleGiftCardProduct,
    'gift-card-section': moduleGiftCardSection,
    'gift-card-template-section': moduleGiftCardTemplateSection,
    'tag': moduleTag,
    'merchant': moduleMerchant,
    'hotspot': moduleHotspot,
    'sendNotification': moduleSendNotification,
    'transaction': moduleTransaction,
    'giftCardTransaction': moduleGiftCardTransaction,
    'paymentRequest': modulePaymentRequest,
    'referral':moduleReferral,
    'bankStatement':moduleBankStatement,
    '2fa':module2FA,
    'revokedAccount':moduleRevokedAccount,
    'transactionVault':moduleTransactionVault,
    'dashboard': moduleDashboard,
    'download': moduleDownload,
    'invoice': moduleInvoice,
    'support': moduleSupport,
    'region': moduleRegion,
    'landingScreen':moduleLandingScreen,
    'app-config': moduleAppConfig,
    'give-away': moduleGiveAway,
    'story': moduleStory,
    'loyaltyCardProduct': moduleLoyaltyCardProduct,
    'post' : modulePost
  },
  strict: process.env.NODE_ENV !== 'production'
})
