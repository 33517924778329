import axios from '../http/axios'
import {generateParams} from '../helpers/helper'

export default {
  async getBankStatementDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)
    console.log(serverQueryObj);
    console.log(filterSortPage);
    console.log(filter);
    return await axios.get('plaid/datatable', {
      params: {
        ...serverQueryObj
      }
    })
  },
  async exportBankStatement(payload) {
      return await axios.get(`plaid/${payload.user_account_id}`,{params:payload});
  },
}
