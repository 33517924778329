import downloadService from "../../services/DownloadService"

export default {
    getDownloadDatatable({ commit }, { eventForServer, filter }) {
        return downloadService
            .getDownloadDatatable(eventForServer, filter)
            .then(({ data }) => {
                commit("SET_DOWNLOADS", data.payload)
                return data
            })
    },
    getDownload(_, id) {
        return downloadService.getDownload(id).then(({ data }) => {
            return data
        })
    },
    updateDownload(_, { id, payload }) {
        return downloadService.updateDownload(id, payload).then(({ data }) => {
            return data
        })
    },
}
