import axios from "../http/axios";

export default {
    async twoFAEnableRequest() {
        return await axios.get("2fa/enable-request");
    },

    async twoFAEnable(payload) {
        return await axios.post("2fa/enable", payload);
    },

    async validate2FA(payload) {
        return await axios.post("2fa/verify", payload);
    }
};
