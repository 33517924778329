export default {

  CREATE_LANDING_SCREEN (state, region) {
    state.landing_screen.data.unshift(region)
  },

  SET_LANDING_SCREEN (state, regions) {
    state.landing_screen = regions
  },



}
