import axios from '../http/axios'
import {generateParams} from '../helpers/helper'

export default {
  async getHotspotDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)
    return await axios.get('hotspot-merchant/datatable', {
      params: serverQueryObj
    })
  },

  async createHotspot (hotspot) {
    return await axios.post('hotspot-merchants', hotspot)
  },

  async updateHotspot (hotspot) {
    return await axios.put(`hotspot-merchants/${hotspot.hotspot_id}`, hotspot)
  },

  async getHotspotList () {
    return await axios.get('hotspot-merchants', null)
  },

  async deleteHotspot (hotspotId) {
    return await axios.delete(`hotspot-merchants/${hotspotId}`)
  }
}
