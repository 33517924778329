
import actions from './moduleDashboardActions'
import mutations from './moduleDashboardMutations'
import state from './moduleDashboardState'
import getters from './moduleDashboardGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
