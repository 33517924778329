import axios from '../http/axios'
import {generateParams} from '../helpers/helper'

export default {
  async getTagDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)
    return await axios.get('tags/datatable', {
      params: serverQueryObj
    })
  },

  async createTag (tag) {
    return await axios.post('tags', tag)
  },

  async updateTag (tag) {
    return await axios.put(`tags/${tag.tag_id}`, tag)
  },

  async getTagList () {
    return await axios.get('tags', null)
  },

  async deleteTag (tagId) {
    return await axios.delete(`tags/${tagId}`)
  }
}
