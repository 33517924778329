
import giftCardProductService from '../../services/GiftCardProductService'

export default {
  getGiftCardProduct (_, giftCardProductId) {
    return giftCardProductService.getGiftCardProduct(giftCardProductId)
      .then(({data}) => {
        return data
      })
  },

  getGiftCardProductDatatable ({commit}, {eventForServer, filter}) {
    return giftCardProductService.getGiftCardProductDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_GIFT_CARD_PRODUCT', data.payload)
        return data
      })
  },

    createGiftCardProduct ({commit}, payload) {
        return giftCardProductService.createGiftCardProduct(payload)
            .then(({data}) => {
                // commit('UPDATE_GIFT_CARD_PRODUCT', data.payload)
                return data
            })
    },

  updateGiftCardProduct ({commit}, { giftCardProductId, payload }) {
    return giftCardProductService.updateGiftCardProduct(giftCardProductId, payload)
      .then(({data}) => {
        // commit('UPDATE_GIFT_CARD_PRODUCT', data.payload)
        return data
      })
  },

    deleteGiftCardProduct ({commit}, giftCardProductId) {
        return giftCardProductService.deleteGiftCardProduct(giftCardProductId)
            .then(({data}) => {
                // commit('UPDATE_GIFT_CARD_PRODUCT', data.payload)
                return data
            })
    },

    dropdownListGiftCardProducts ({commit}) {
        return giftCardProductService.dropdownListGiftCardProducts()
            .then(({data}) => {
                // commit('UPDATE_GIFT_CARD_PRODUCT', data.payload)
                return data
            })
    }
}
