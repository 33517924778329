
import actions from './moduleTransactionVaultActions'
import getters from './moduleTransactionVaultGetters'
import mutations from './moduleTransactionVaultMutations'
import state from './moduleTransactionVaultState'

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
