export default {

  CREATE_REGION (state, region) {
    state.region.data.unshift(region)
  },

  SET_REGION (state, regions) {
    state.region = regions
  },

  UPDATE_REGION (state, region) {
    state.region.data = state.region.data.map((s) => {
      return (s.id == region.id) ? (s = region) : s
    })
  },

  DELETE_REGION (state, id) {
    state.region.data = state.region.data.filter((s) => (s.id != id))
    state.region.meta.total = state.region.meta.total - 1
  }

}
