import axios from '../../axios'
import store from '@/store/store'
import router from '@/router'

// Request interceptor
axios.interceptors.request.use(request => {
  const token = localStorage.getItem('accessToken')
  if (token) {
    request.headers.common['Authorization'] = `Bearer ${token}`
  }
  return request
})


axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status == 401) {
      // console.log('AXIOS INTERCEPTOR : LOGOUT LOCAL')
      store.dispatch('auth/logoutLocally')
      router.push({name: 'login'})
    }

    return Promise.reject(error)
  }
)

export default axios
