
import actions from './moduleLandingScreenActions'
import mutations from './moduleLandingScreenMutations'
import state from './moduleLandingScreenState'
import getters from './moduleLandingScreenGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
