
import giftCardCategoryService from '../../services/GiftCardCategoryService'

export default {
  getGiftCardCategoryDatatable ({commit}, {eventForServer, filter}) {
    return giftCardCategoryService.getGiftCardCategoryDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_GIFT_CARD_CATEGORY', data.payload)
        return data
      })
  },

  getGiftCardCategories () {
    return giftCardCategoryService.getGiftCardCategories()
      .then(({data}) => {
        return data
      })
  },

  createGiftCardCategory (context, payload) {
    return giftCardCategoryService.createGiftCardCategory(payload)
      .then(({data}) => {
        context.commit('CREATE_GIFT_CARD_CATEGORY', data.payload)
        return data
      })
  },

  updateGiftCardCategory ({commit}, payload) {
    return giftCardCategoryService.updateGiftCardCategory(payload)
      .then(({data}) => {
        commit('UPDATE_GIFT_CARD_CATEGORY', data.payload)
        return data
      })
  },

  deleteGiftCardCategory ({commit}, giftCardCategoryId) {
    return giftCardCategoryService.deleteGiftCardCategory(giftCardCategoryId)
      .then(() => {
        commit('DELETE_GIFT_CARD_CATEGORY', giftCardCategoryId)
      })
  }
}
