
import banner from '../../services/BannerService'

export default {

  createBanner (context, payload) {
    return banner.createBanner(payload)
      .then((response) => {
        context.commit('CREATE_BANNER', response.data.payload)
        return response.data
      })
  },

  getBannerDatatable ({commit}, {eventForServer, filter}) {
    return banner.getBannerDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_BANNER', data.payload)
        return data
      })
  },

  updateBanner ({commit}, payload) {
    return banner.updateBanner(payload)
      .then(({data}) => {
        commit('UPDATE_BANNER', data.payload)
        return data
      })
  },

  getBannerList () {
    return banner.getBannerList()
      .then(({data}) => {
        return data.payload.data
      })
  },

  deleteBanner ({commit}, bannerId) {
    return banner.deleteBanner(bannerId)
      .then(() => {
        commit('DELETE_BANNER', bannerId)
      })
  }
}
