import axios from '../../http/axios'
import appConfig from "../../services/AppConfigService"
export default {
  // sendAppConfig (context, payload) {
  //   return axios.post('send-notification', payload, {
  //       timeout: 0,
  //   })
  // },
  async getAppConfig () {
    const response =  await appConfig.getAppConfig()
    return response
  },
  sendAppConfig (context, payload) {
    return appConfig.sendAppConfigRequest(payload)
  },


}
