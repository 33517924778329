import config from '@/config/constants'

export default {
    user: state => state.user,

    authUserRole: state => state.user.role || config.roles.admin,

    isAdmin: state => (state.user.role === config.roles.admin),

    isMerchant: state => (state.user.role === config.roles.merchant),

    isLoggedIn: state => {
        if (state.user === null || state.user === "") {
            return 0;
        }
        return 1;
    },

    need2FA: (state, getters, rootState, rootGetters) => {
        console.log("Need 2fa called");
        if (!state.user) {
            return 0;
        }
        const current2FAStatus = rootGetters["2fa/is2FAValidated"];

        if (state.user.is_2fa_enabled && !current2FAStatus) {
            return 1;
        }
        return 0;
    }
};
