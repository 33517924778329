
export default {
  referrals: {
    data: [],
    links: {},
    meta: {}
  },

  referralDetails: {
    data: [],
    links: {},
    meta: {}
  }
}
