export default {
  CREATE_MERCHANT (state, merchant) {
    state.merchants.data.unshift(merchant)
  },

  SET_MERCHANT (state, merchants) {
    state.merchants = merchants
  },

  UPDATE_MERCHANT (state, merchant) {
    state.merchants.data = state.merchants.data.map((m) => {
      return (m.merchant_id == merchant.merchant_id) ? (m = merchant) : m
    })
  },

  DELETE_MERCHANT (state, merchantId) {
    state.merchants.data = state.merchants.data.filter((s) => (s.merchant_id != merchantId))
    state.merchants.meta.total = state.merchants.meta.total - 1
  }
}
