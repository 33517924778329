import axios from "../http/axios"
import { generateParams } from "../helpers/helper"

export default {
    async getLoyaltyCardProductDatatable(filterSortPage, filter) {
        const serverQueryObj = generateParams(filterSortPage, filter)
        return await axios.get("loyalty-card-products/datatable", {
            params: serverQueryObj,
        })
    },
    async addLoyaltyCardProduct(payload) {
        return await axios.post('loyalty-card-product', payload)
    },

    async updateLoyaltyCardProduct(id, payload) {
        return await axios.put(`loyalty-card-product/${id}`, payload)
    },

    async deleteLoyaltyCardProduct(id) {
        return await axios.delete(`loyalty-card-product/${id}`)
  },

  async getLoyaltyCardProduct(id) {
    return await axios.get(`loyalty-card-product/${id}`)
  },
}
