import loyaltyCardProduct from "../../services/LoyaltyCardProductService"

export default {
    getLoyaltyCardProductDatatable({ commit }, { eventForServer, filter }) {
        return loyaltyCardProduct
            .getLoyaltyCardProductDatatable(eventForServer, filter)
            .then(({ data }) => {
                commit("SET_LOYALTY_CARD_PRODUCT", data.payload)
                return data
            })
    },
    addLoyaltyCardProduct(_, payload) {
        return loyaltyCardProduct.addLoyaltyCardProduct(payload).then(({ data }) => {
            return data
        })
    },

    updateLoyaltyCardProduct(_, { id, payload }) {
        return loyaltyCardProduct.updateLoyaltyCardProduct(id, payload).then(({ data }) => {
            return data
        })
    },

    deleteLoyaltyCardProduct(_, id) {
        return loyaltyCardProduct.deleteLoyaltyCardProduct(id).then(({ data }) => {
            return data
        })
    },

    getLoyaltyCardProduct(_, id) {
        return loyaltyCardProduct.getLoyaltyCardProduct(id).then(({ data }) => {
            return data
        })
    },
}
