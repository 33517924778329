
import actions from '../tag/moduleTagActions'
import mutations from '../tag/moduleTagMutations'
import state from '../tag/moduleTagState'
import getters from '../tag/moduleTagGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
