
import actions from '../hotspot/moduleHotspotActions'
import mutations from '../hotspot/moduleHotspotMutations'
import state from '../hotspot/moduleHotspotState'
import getters from '../hotspot/moduleHotspotGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
