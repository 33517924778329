export default {
  CREATE_GIVE_AWAY (state, giveAway) {
    state.giveAway.data.unshift(giveAway)
  },

  SET_GIVE_AWAY (state, giveAway) {
    state.giveAway = giveAway
  },

  UPDATE_GIVE_AWAY (state, giveAwayID) {
    state.giveAway.data = state.giveAway.data.map((s) => {
      return (s.id == giveAwayID.id) ? (s = giveAwayID) : s
    })
  },

  DELETE_GIVE_AWAY (state, giveAwayID) {
    state.giveAway.data = state.giveAway.data.filter((s) => (s.id != giveAwayID))
    state.giveAway.meta.total = state.giveAway.meta.total - 1
  }

}
