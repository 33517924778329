import axios from '../http/axios'
import {generateParams} from '../helpers/helper'

export default {
  async getReferralDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)
    return await axios.get('referrals/datatable', {
      params: {
        ...serverQueryObj,
      }
    })
  },

  async getReferralDetailDatatable (filterSortPage, filter, userId) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get(`users/${userId}/referrals/datatable`, {
      params: serverQueryObj
    })
  }

  // async updatePaymentRequestStatus (payload) {
  //   return await axios.patch(`withdraw/${payload.withdraw_id}/change-status`, payload)
  // }
}
