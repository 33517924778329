import axios from '../http/axios'
import {generateParams} from '../helpers/helper'

export default {
  async getRevokedAccountDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('plaid/revoked-account-datatable', {
      params: {
        ...serverQueryObj
      }
    })
  }

}
