export default {

  CREATE_GIFT_CARD_CATEGORY (state, category) {
    state.giftCardCategory.data.unshift(category)
  },

  SET_GIFT_CARD_CATEGORY (state, categories) {
    state.giftCardCategory = categories
  },

  UPDATE_GIFT_CARD_CATEGORY (state, giftCardCategory) {
    state.giftCardCategory.data = state.giftCardCategory.data.map((s) => {
      return (s.id == giftCardCategory.id) ? (s = giftCardCategory) : s
    })
  },

  DELETE_GIFT_CARD_CATEGORY (state, giftCardCategoryId) {
    state.giftCardCategory.data = state.giftCardCategory.data.filter((s) => (s.id != giftCardCategoryId))
    state.giftCardCategory.meta.total = state.giftCardCategory.meta.total - 1
  }

}
