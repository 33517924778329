
import actions from './moduleDownloadActions'
import mutations from './moduleDownloadMutations'
import state from './moduleDownloadState'
import getters from './moduleDownloadGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
