/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import Router from 'vue-router'
import Store from './store/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import('./views/pages/home/Home.vue'),
          meta: {
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/app-config',
          name: 'app-config',
          component: () => import(
            './views/pages/app-configs/AppConfigAdmin.vue'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'App Config', active: true }
            ],
            pageTitle: 'App Config',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/banner',
          name: 'banner',
          component: () => import(
            './views/pages/banner/BannerList.vue'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Banner', active: true }
            ],
            pageTitle: 'Banner',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/tag',
          name: 'tag',
          component: () => import('./views/pages/tag/TagList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Tag', active: true }
            ],
            pageTitle: 'Tag',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/settings',
          name: 'settings',
          component: () => import('./views/pages/settings/Settings'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Settings', active: true }
            ],
            pageTitle: 'Settings',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/settings/change-profile-picture',
          name: 'change-profile-picture',
          component: () => import('./views/pages/settings/ChangeProfilePicture'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Settings', url: 'settings' },
              { title: 'Change Profile Picture', active: true }
            ],
            pageTitle: 'Change Profile Picture',
            parent: 'settings',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/change-password',
          name: 'change-password',
          component: () => import(
                            './views/pages/change-password/ChangePassword.vue'
                        ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Settings', url: 'settings' },
              { title: 'Change Password', active: true }
            ],
            pageTitle: 'Change Password',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/2fa',
          name: '2fa',
          component: () => import(
                            './views/pages/two-factor-auth/TwoFactorAuth.vue'
                        ),
          // component: () => import('./views/pages/Ask2FAScreen.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Settings', url: 'settings' },
              { title: 'Two Factor Auth', active: true }
            ],
            pageTitle: 'Two Factor Auth',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/category',
          name: 'category',
          component: () => import('./views/pages/category/CategoryList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Category', active: true }
            ],
            pageTitle: 'Category',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/gift-card-category',
          name: 'gift-card-category',
          component: () => import('./views/pages/gift-card-category/GiftCardCategoryList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Gift Card Category', active: true }
            ],
            pageTitle: 'Gift Card Category',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/gift-card-section',
          name: 'gift-card-section',
          component: () => import('./views/pages/gift-card-section/GiftCardSectionList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Gift Card Section', active: true }
            ],
            pageTitle: 'Gift Card Section',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/gift-card-section/add',
          name: 'add-gift-card-section',
          component: () => import('./views/pages/gift-card-section/AddGiftCardSection.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Gift Card Section', url: '/gift-card-section' },
              { title: 'Add Gift Card Section', active: true }
            ],
            pageTitle: 'Add Gift Card Section',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/gift-card-section/:id/edit',
          name: 'edit-gift-card-section',
          component: () => import('./views/pages/gift-card-section/EditGiftCardSection.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Gift Card Section', url: '/gift-card-section' },
              { title: 'Edit Gift Card Section', active: true }
            ],
            pageTitle: 'Edit Gift Card Section',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/gift-card-template-section',
          name: 'gift-card-template-section',
          component: () => import('./views/pages/gift-card-template-section/GiftCardTemplateSectionList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Gift Card Template Section', active: true }
            ],
            pageTitle: 'Gift Card Template Section',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/gift-card-template-section/add',
          name: 'add-gift-card-template-section',
          component: () => import('./views/pages/gift-card-template-section/AddGiftCardTemplateSection.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Gift Card Template Section', url: '/gift-card-template-section' },
              { title: 'Add Gift Card Template Section', active: true }
            ],
            pageTitle: 'Add Gift Card Template Section',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/gift-card-template-section/:id/edit',
          name: 'edit-gift-card-template-section',
          component: () => import('./views/pages/gift-card-template-section/EditGiftCardTemplateSection.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Gift Card Template Section', url: '/gift-card-template-section' },
              { title: 'Edit Gift Template Card Section', active: true }
            ],
            pageTitle: 'Edit Gift Card Template Section',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/give-away',
          name: 'give-away',
          component: () => import('./views/pages/give-away/GiveAwayList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Give Away', active: true }
            ],
            pageTitle: 'Give Away',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/give-away/add',
          name: 'add-give-away',
          component: () => import('./views/pages/give-away/AddGiveAway.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Give Away', url: '/give-away' },
              { title: 'Add Give Away', active: true }
            ],
            pageTitle: 'Add Give Away',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/give-away/:id/edit',
          name: 'edit-give-away',
          component: () => import('./views/pages/give-away/EditGiveAway.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Gift Give Away', url: '/give-away' },
              { title: 'Edit Give Away', active: true }
            ],
            pageTitle: 'Edit Give Away',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/loyalty-card-products',
          name: 'loyalty-card-products',
          component: () => import('./views/pages/loyalty-card-product/LoyaltyCardProductsList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Loyalty Card Products', active: true }
            ],
            pageTitle: 'Loyalty Card Products',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/loyalty-card-product/add',
          name: 'add-loyalty-card-product',
          component: () => import('./views/pages/loyalty-card-product/AddLoyaltyCardProduct.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Loyalty Card Products', url: '/loyalty-card-products' },
              { title: 'Add Loyalty Card Product', active: true }
            ],
            pageTitle: 'Add Loyalty Card Product',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/loyalty-card-product/:id/edit',
          name: 'edit-loyalty-card-product',
          component: () => import('./views/pages/loyalty-card-product/EditLoyaltyCardProduct.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Loyalty Card Products', url: '/loyalty-card-products' },
              { title: 'Edit Loyalty Card Product', active: true }
            ],
            pageTitle: 'Edit Loyalty Card Product',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/gift-card-product',
          name: 'gift-card-product',
          component: () => import('./views/pages/gift-card-product/GiftCardProductList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Gift Card Product', active: true }
            ],
            pageTitle: 'Gift Card Product',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/gift-card-product/add',
          name: 'add-gift-card-product',
          component: () => import('./views/pages/gift-card-product/AddGiftCardProduct.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Gift Card Product', url: '/gift-card-product' },
              { title: 'Add Gift Card Product', active: true }
            ],
            pageTitle: 'Add Gift Card Product',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/gift-card-product/:id/edit',
          name: 'edit-gift-card-product',
          component: () => import('./views/pages/gift-card-product/EditGiftCardProduct.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Gift Card Product', url: '/gift-card-product' },
              { title: 'Edit Gift Card Product', active: true }
            ],
            pageTitle: 'Edit Gift Card Product',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/post/add',
          name: 'add-post',
          component: () => import('./views/pages/post/PostAddAdmin.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'post', url: '/post' },
              { title: 'Add Post', active: true }
            ],
            pageTitle: 'Add Post',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/stories',
          name: 'stories',
          component: () => import('./views/pages/stories/StoriesList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Stories', active: true }
            ],
            pageTitle: 'Stories',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/story/add',
          name: 'add-story',
          component: () => import('./views/pages/stories/AddStory.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Stories', url: '/stories' },
              { title: 'Add Story', active: true }
            ],
            pageTitle: 'Add Story',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/story/:id/edit',
          name: 'edit-story',
          component: () => import('./views/pages/stories/EditStory.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Stories', url: '/stories' },
              { title: 'Edit Story', active: true }
            ],
            pageTitle: 'Edit Story',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/merchant',
          name: 'merchant',
          component: () => import('./views/pages/merchant/MerchantList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Businesses', active: true }
            ],
            pageTitle: 'Businesses',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/merchant/add',
          name: 'add-merchant',
          component: () => import('./views/pages/merchant/AddMerchant'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Businesses', url: '/merchant' },
              { title: 'Add Business', active: true }
            ],
            pageTitle: 'Add Business',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/merchant/:id/edit',
          name: 'edit-merchant',
          component: () => import('./views/pages/merchant/EditMerchant'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Businesses', url: '/merchant' },
              { title: 'Edit Business', active: true }
            ],
            pageTitle: 'Edit Business',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/merchant/:id',
          name: 'show-merchant',
          component: () => import('./views/pages/merchant/ShowMerchant'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Businesses', url: '/merchant' },
              { title: 'Show', active: true }
            ],
            pageTitle: 'Show',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/hotspot',
          name: 'hotspot',
          component: () => import('./views/pages/hotspot/HotspotList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Hotspot', active: true }
            ],
            pageTitle: 'Hotspot',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/send-notification',
          name: 'send-notification',
          component: () => import(
                            './views/pages/send-notification/SendNotificationAdmin'
                        ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Send Notification', active: true }
            ],
            pageTitle: 'Send Notification',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/push-notifications',
          name: 'push-notifications',
          component: () => import(
            './views/pages/push-notification/PushNotificationMerchant'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Push Notifications', active: true }
            ],
            pageTitle: 'Push Notifications',
            rule: 'merchant',
            needAuth: true
          }
        },
        {
          path: '/push-notification-for-merchant',
          name: 'push-notification-for-merchant',
          component: () => import(
            './views/pages/push-notification-for-merchant/PushNotificationForMerchant'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Push Notification for Merchant', active: true }
            ],
            pageTitle: 'Push Notification for Merchant',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/transactions',
          name: 'transaction-list',
          component: () => import('./views/pages/transactions/TransactionList'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Transactions', active: true }
            ],
            pageTitle: 'Transactions',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/gift-card-transactions',
          name: 'gift-card-transaction-list',
          component: () => import('./views/pages/gift-card-transactions/GiftCardTransactionList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'GiftCard Transactions', active: true }
            ],
            pageTitle: 'GiftCard Transactions',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/revenue',
          name: 'revenue',
          component: () => import('./views/pages/revenue/Revenue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Revenue', active: true }
            ],
            pageTitle: 'Revenue',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/payment-request',
          name: 'payment-request',
          component: () => import('./views/pages/payment-request/PaymentRequest'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Payment Request', active: true }
            ],
            pageTitle: 'Payment Request',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/payment-history',
          name: 'payment-history',
          component: () => import('./views/pages/payment-history/PaymentHistory'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Payment History', active: true }
            ],
            pageTitle: 'Payment History',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/referral',
          name: 'referral',
          component: () => import('./views/pages/referral/ReferralDatatable'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Referral', active: true }
            ],
            pageTitle: 'Referral',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/bank-statement',
          name: 'bank-statement',
          component: () => import(
                            './views/pages/bank-statement/BankStatementList'
                        ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Linked Accounts', active: true }
            ],
            pageTitle: 'Linked Accounts',
            rule: 'admin',
            needAuth: true
          }

        },
        {
          path: '/revoked-accounts',
          name: 'revoked-accounts',
          component: () => import(
                              './views/pages/revoked-accounts/RevokedAccount'
                          ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Revoked Accounts', active: true }
            ],
            pageTitle: 'Revoked Accounts',
            rule: 'admin',
            needAuth: true
          }

        },
        {
          path: '/transaction-vault',
          name: 'transaction-vault',
          component: () => import('./views/pages/transactions-vault/TransactionVaultList'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Vault Transactions', active: true }
            ],
            pageTitle: 'Vault Transactions',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/downloads',
          name: 'downloads',
          component: () => import('./views/pages/downloads/DownloadList'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Downloads', active: true }
            ],
            pageTitle: 'Downloads',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/download/:id/edit',
          name: 'edit-download',
          component: () => import('./views/pages/downloads/EditDownload'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Downloads', url: '/downloads' },
              { title: 'Edit Download', active: true }
            ],
            pageTitle: 'Edit Download',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/invoices',
          name: 'invoices',
          component: () => import('./views/pages/invoice/InvoiceList'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Invoices', active: true }
            ],
            pageTitle: 'Invoices',
            rule: 'merchant',
            needAuth: true
          }
        },
        {
          path: '/invoices/add',
          name: 'add-invoices',
          component: () => import('./views/pages/invoice/AddInvoice'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Invoices', url: '/invoices' },
              { title: 'Add', active: true }
            ],
            pageTitle: 'Add Invoice',
            rule: 'admin',
            needAuth: true
          }
        },
        {
          path: '/your-business',
          name: 'your-business',
          component: () => import('./views/pages/your-business/ShowYourBusiness'),
          props: true,
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Your business', active: true }
            ],
            pageTitle: 'Your business',
            rule: 'merchant',
            needAuth: true
          }
        },
        {
          path: '/support',
          name: 'support',
          component: () => import('./views/pages/support/Support'),
          props: true,
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Settings', url: '/settings' },
              { title: 'Support', active: true }
            ],
            pageTitle: 'Support',
            rule: 'merchant',
            needAuth: true
          }
        },
        {
          path: '/users/:id/referrals',
          name: 'user-referral-datatable',
          component: () => import('./views/pages/referral/RefereeDetailDatatable'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Referral', active: true }
            ],
            pageTitle: 'Referral',
            rule: 'admin',
            needAuth: true
          }
        }
      ]
    },

    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            authRoute: true
          }
        },

        {
          path: '/2fa-code',
          name: '2fa-code',
          component: () => import('@/views/pages/Ask2FAScreen.vue'),
          meta: {
            needAuth: true,
            is2FA: true
          }
        },
        {
          path: '/forgot-password',
          name: 'forgot-password',
          component: () => import(
                            './views/pages/forgot-password/ForgotPassword.vue'
                        ),
          meta: {
            authRoute: true
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach(async (to, from, next) => {
  console.log(Store.getters['auth/isLoggedIn'])
  if (to.matched.some(record => record.meta.needAuth)) {
    if (
      Store.getters['auth/isLoggedIn'] === 0 &&
            localStorage.getItem('accessToken') === null
    ) {
      next({
        name: 'login'
      })
    } else if (
      Store.getters['auth/isLoggedIn'] === 0 &&
            localStorage.getItem('accessToken') !== null
    ) {
      try {
        await Store.dispatch('auth/getLoggedInUserProfile')
        manageRedirectForAuth(to, next)
        // next();
      } catch (e) {
        await Store.dispatch('auth/logoutLocally')
        next({
          name: 'login'
        })
      }
    } else {
      manageRedirectForAuth(to, next)
    }
  } else if (to.matched.some(record => record.meta.authRoute)) {
    if (Store.getters['auth/isLoggedIn'] === 1) {
      manageRedirectForAuth(to, next, true)
    } else if (localStorage.getItem('accessToken') !== null) {
      try {
        await Store.dispatch('auth/getLoggedInUserProfile')
        manageRedirectForAuth(to, next, true)
      } catch (e) {
        await Store.dispatch('auth/logoutLocally')
        next()
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

const manageRedirectForAuth = (to, next, redirectToHome = false) => {
  if (to.matched.some(record => record.meta.is2FA)) {
    next()
  } else if (Store.getters['auth/need2FA']) {
    next({
      name: '2fa-code'
    })
  } else if (redirectToHome) {
    next({
      name: 'home'
    })
  } else {
    next()
  }
}

export default router
