export default {

  CREATE_BANNER (state, banner) {
    state.banner.data.unshift(banner)
  },

  SET_BANNER (state, banners) {
    state.banner = banners
  },

  UPDATE_BANNER (state, banner) {
    state.banner.data = state.banner.data.map((s) => {
      return (s.id == banner.id) ? (s = banner) : s
    })
  },

  DELETE_BANNER (state, bannerId) {
    state.banner.data = state.banner.data.filter((s) => (s.id != bannerId))
    state.banner.meta.total = state.banner.meta.total - 1
  }

}
