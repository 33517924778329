import storyService from "../../services/StoryService"

export default {
    getStoryDatatable({ commit }, { eventForServer, filter }) {
        return storyService
            .getStoryDatatable(eventForServer, filter)
            .then(({ data }) => {
                commit("SET_STORY", data.payload)
                return data
            })
    },
    addStory(_, payload) {
        return storyService.addStory(payload).then(({ data }) => {
            return data
        })
    },

    updateStory(_, { id, payload }) {
        return storyService.updateStory(id, payload).then(({ data }) => {
            return data
        })
    },

    deleteStory(_, id) {
        return storyService.deleteStory(id).then(({ data }) => {
            return data
        })
    },

    getStory(_, id) {
        return storyService.getStory(id).then(({ data }) => {
            return data
        })
    },
}
