import axios from '../http/axios/'
import {generateParams} from '../helpers/helper'

export default {

  async getLandingScreen() {
    return await axios.get('landing-screen')
  }



}
