
import actions from './moduleBannerActions'
import mutations from './moduleBannerMutations'
import state from './moduleBannerState'
import getters from './moduleBannerGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
