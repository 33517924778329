
import actions from './moduleRegionActions'
import mutations from './moduleRegionMutations'
import state from './moduleRegionState'
import getters from './moduleRegionGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
