import actions from './moduleMerchantActions'
import mutations from './moduleMerchantMutations'
import state from './moduleMerchantState'
import getters from './moduleMerchantGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
