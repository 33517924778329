export default {
  SET_BANK_STATEMENT (state, bankStatements) {
    state.bankStatements = bankStatements
  },

  CLEAR_BANK_STATEMENT (state) {
    state.bankStatements.data = []
    state.bankStatements.links = {}
    state.bankStatements.meta = {}
  },


}
