export default {
    LOGIN_USER(state, user) {
        state.user = user;
    },

    SET_USER(state, user) {
      state.user = user;
    },

    FETCH_USER(state, user) {
        state.user = user;
    },

    TWO_FA_VALIDATED(state) {
        state.user.is_2fa_validated = 1;
    },
    TWO_FA_ENABLED(state) {
        state.user.is_2fa_validated = 1;
        state.user.is_2fa_enabled = 1;
    },

    LOGOUT_USER(state) {
        state.user = null;
        localStorage.removeItem("accessToken");
        localStorage.removeItem("two_fa_validated");
        localStorage.removeItem("_expiredTime");
        localStorage.removeItem("please_clear_double_2fa");
        localStorage.removeItem("cleared_double_2fa");
        localStorage.removeItem("route_intent");

        // axios.defaults.headers.common['Authorization'] = null
    }
};
