
import actions from './moduleStoryActions'
import mutations from './moduleStoryMutations'
import state from './moduleStoryState'
import getters from './moduleStoryGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
