import paymentRequest from '../../services/PaymentRequestService'

export default {
  getPaymentRequestDatatable ({commit}, {eventForServer, filter, status}) {
    return paymentRequest.getPaymentRequestDatatable(eventForServer, filter, status)
      .then(({data}) => {
        if(filter.toDownload){
          // if we are calling api for export then we will not update the state
          return data;
        }
        commit('SET_PAYMENT_REQUEST', data.payload)
        return data
      })
  },

  updatePaymentRequestStatus ({commit}, payload) {
    return paymentRequest.updatePaymentRequestStatus(payload)
      .then(({data}) => {
        commit('DELETE_PAYMENT_REQUEST', payload.withdraw_id)
        commit('DECREASE_PENDING_PAYMENT_COUNT');
        return data
      })
  },

  updateBulkPaymentRequestStatus ({commit}, payload) {
    return paymentRequest.updateBulkPaymentRequestStatus(payload)
      .then(({data}) => {
        payload.withdraw_ids.forEach((withdraw_id) => {
          commit('DELETE_PAYMENT_REQUEST', withdraw_id)
        });
        commit('DECREASE_PENDING_PAYMENT_COUNT');
        return data
      })
  },

    getPendingPaymentCount ({commit}) {
      return paymentRequest.getPendingPaymentCount()
        .then(({data})=>{
          commit('SET_PENDING_PAYMENT_COUNT',data.payload.count);
          return data
        })
    },

  clearPaymentRequestData ({commit}) {
    commit('CLEAR_PAYMENT_REQUEST')
  },




}
