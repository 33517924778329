export default {
  SET_TAG (state, tags) {
    state.tags = tags
  },

  CREATE_TAG (state, tag) {
    state.tags.data.unshift(tag)
  },

  UPDATE_TAG (state, tag) {
    // const updateableData = [...state.tags.data]
    // const index = updateableData.findIndex(item => item.tag_id === tag.tag_id)
    // updateableData[index] = {...tag}
    // // state.tags = {...state.tags, data: [...updateableData]}
    // state.tags.data = [...updateableData]

    state.tags.data = state.tags.data.map((s) => {
      return (s.tag_id === tag.tag_id) ? (s = tag) : s
    })
  },

  DELETE_TAG (state, tagId) {
    state.tags.data = state.tags.data.filter((s) => (s.tag_id != tagId))
    state.tags.meta.total = state.tags.meta.total - 1
  }
}
