
import axios from '../http/axios'
import { generateParams } from '../helpers/helper'

export default {
  async getTransactionDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)
    const config = (filter.hasOwnProperty('toDownload') && (filter.toDownload == 1))
      ? { timeout: 0, responseType: 'blob' }
      : {}

    return await axios.get('transaction/datatable', {
      params: serverQueryObj,
      ...config
    })
  }
}
