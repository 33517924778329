
import actions from './moduleGiftCardCategoryActions'
import mutations from './moduleGiftCardCategoryMutations'
import state from './moduleGiftCardCategoryState'
import getters from './moduleGiftCardCategoryGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
