
import actions from './moduleSupportActions'
import getters from './moduleSupportGetters'
import mutations from './moduleSupportMutations'
import state from './moduleSupportState'

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations
}
