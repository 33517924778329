
import actions from './moduleCategoryActions'
import mutations from './moduleCategoryMutations'
import state from './moduleCategoryState'
import getters from './moduleCategoryGetters'

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
}
