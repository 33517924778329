/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// @vue/composition-api
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)

// Vuesax Component Framework
import Vuesax from 'vuesax'

Vue.use(Vuesax)


// axios
import axios from './axios.js'
Vue.prototype.$http = axios

// Primevue css part
// import ('primevue/resources/themes/saga-blue/theme.css')       //theme
// import('primevue/resources/primevue.min.css')                 //core css
// import('primeicons/primeicons.css')                           //icons

// Primevue : ToastService
import ToastService from 'primevue/toastservice'
Vue.use(ToastService)


// Filters
import './filters/filters.js'


// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// AWS Amplify
import Amplify from 'aws-amplify'
import awsconfig from './aws-amplify/aws-exports'
Amplify.configure(awsconfig)


// DayJs Plugin
import dayjs from './plugins/DayjsPlugin'
Vue.use(dayjs)

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
