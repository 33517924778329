export default {
  SET_HOTSPOT (state, hotspots) {
    state.hotspots = hotspots
  },

  CREATE_HOTSPOT (state, hotspot) {
    state.hotspots.data.unshift(hotspot)
  },

  UPDATE_HOTSPOT (state, hotspot) {
    // const updateableData = [...state.tags.data]
    // const index = updateableData.findIndex(item => item.tag_id === tag.tag_id)
    // updateableData[index] = {...tag}
    // // state.tags = {...state.tags, data: [...updateableData]}
    // state.tags.data = [...updateableData]

    state.hotspots.data = state.hotspots.data.map((s) => {
      return (s.hotspot_id === hotspot.hotspot_id) ? (s = hotspot) : s
    })
  },

  DELETE_HOTSPOT (state, hotspotId) {
    state.hotspots.data = state.hotspots.data.filter((s) => (s.hotspot_id != hotspotId))
    state.hotspots.meta.total = state.hotspots.meta.total - 1
  }
}
