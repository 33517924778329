
import category from '../../services/CategoryService'

export default {

  createCategory (context, payload) {
    return category.createCategory(payload)
      .then((response) => {
        context.commit('CREATE_CATEGORY', response.data.payload)
        return response.data
      })
  },

  getCategoryDatatable ({commit}, {eventForServer, filter}) {
    return category.getCategoryDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_CATEGORY', data.payload)
        return data
      })
  },

  updateCategory ({commit}, payload) {
    return category.updateCategory(payload)
      .then(({data}) => {
        commit('UPDATE_CATEGORY', data.payload)
        return data
      })
  },

  getCategoryList () {
    return category.getCategoryList()
      .then(({data}) => {
        return data.payload.data
      })
  },

  deleteCategory ({commit}, categoryId) {
    return category.deleteCategory(categoryId)
      .then(() => {
        commit('DELETE_CATEGORY', categoryId)
      })
  }
}
