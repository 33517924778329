import revokedAccount from '../../services/RevokedAccountService'

export default {
  getRevokedAccountDatatable ({commit}, {eventForServer, filter}) {
    return revokedAccount.getRevokedAccountDatatable(eventForServer, filter)
      .then(({data}) => {

        commit('SET_REVOKED_ACCOUNT', data.payload)
        return data
      })
  },


  clearRevokedAccountData ({commit}) {
    commit('CLEAR_REVOKED_ACCOUNT')
  }


}
