import tag from '../../services/TagService'

export default {
  getTagDatatable ({commit}, {eventForServer, filter}) {
    return tag.getTagDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_TAG', data.payload)
        return data
      })
  },

  createTag ({commit}, payload) {
    return tag.createTag(payload)
      .then(({data}) => {
        commit('CREATE_TAG', data.payload)
        return data
      })
  },

  updateTag ({commit}, payload) {
    return tag.updateTag(payload)
      .then((response) => {
        commit('UPDATE_TAG', response.data.payload)
        return response.data
      })
  },

  getTagList () {
    return tag.getTagList()
      .then(({data}) => {
        return data.payload.data
      })
  },

  deleteTag ({commit}, tagId) {
    return tag.deleteTag(tagId)
      .then(() => {
        commit('DELETE_TAG', tagId)
      })
  }
}
