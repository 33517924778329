import axios from '../http/axios'
import {generateParams} from '../helpers/helper'

export default {
  async createMerchant (payload) {
    return await axios.post('merchant', payload)
  },

  async getMerchantDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)
    return await axios.get('merchant/datatable', {
      params: serverQueryObj
    })
  },

  async getSingleMerchant (merchantId) {
    return await axios.get(`merchant/${merchantId}`)
  },

  async updateMerchant (payload) {
    return await axios.put(`merchant/${payload.merchant_id}`, payload)
  },

  async deleteMerchant (merchantId) {
    return await axios.delete(`merchant/${merchantId}`)
  },

  async getAllMerchant () {
    return await axios.get('merchant/all')
  },
  async getNotHostListedMerchant () {
    return await axios.get('merchant/not-hostspot/list')
  }
}
