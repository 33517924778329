import axios from '../../http/axios'
import pushNotificationService from '@/services/PushNotificationService'
import merchant from "../../services/MerchantService"

export default {
  sendNotification (context, payload) {
    return axios.post('send-notification', payload, {
        timeout: 0,
    })
  },

  sendPushNotificationRequest (context, payload) {
    return pushNotificationService.sendPushNotificationRequest(payload)
  },

  changeStatusOfPushNotificationRequest (context, payload) {
    return pushNotificationService.changeStatusOfPushNotificationRequest(payload)
  },

  getPushNotificationsForMerchantDatatable ({commit}, {
    eventForServer,
    filter
  }) {
    return pushNotificationService.getPushNotificationsForMerchantDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_PUSH_NOTIFICATIONS_FOR_MERCHANT', data.payload)

        return data
      })
  }
}
