
import region from '../../services/RegionService'

export default {

  // createRegion (context, payload) {
  //   return region.createRegion(payload)
  //     .then((response) => {
  //       context.commit('CREATE_REGION', response.data.payload)
  //       return response.data
  //     })
  // },
  //
  // getRegionDatatable ({commit}, {eventForServer, filter}) {
  //   return region.getRegionDatatable(eventForServer, filter)
  //     .then(({data}) => {
  //       commit('SET_REGION', data.payload)
  //       return data
  //     })
  // },
  //
  // updateRegion({commit}, payload) {
  //   return region.updateRegion(payload)
  //     .then(({data}) => {
  //       commit('UPDATE_REGION', data.payload)
  //       return data
  //     })
  // },

  getRegionList () {
    return region.getRegionList()
      .then(({data}) => {
        return data.payload.data
      })
  },

  // deleteRegion ({commit}, id) {
  //   return region.deleteRegion(id)
  //     .then(() => {
  //       commit('DELETE_REGION', id)
  //     })
  // }
}
