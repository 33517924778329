import { generateParams } from '../helpers/helper'
import axios from '../http/axios'

export default {
  async getInvoiceDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('invoices', {
      params: serverQueryObj
    })
  },

  async createInvoice (payload) {
    return await axios.post('invoices', payload)
  }
}
