
import post from '../../services/PostService'

export default {

  createPost(context, payload) {
    return post.createPost(payload)
      .then((response) => {
        context.commit('CREATE_POST', response.data.payload)
        return response.data
      })
  },

  getPostDatatable ({commit}, {eventForServer, filter}) {
    return post.getPostDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_POST', data.payload)
        return data
      })
  },

  updatePost ({commit}, payload) {
    return post.updatePost(payload)
      .then(({data}) => {
        commit('UPDATE_POST', data.payload)
        return data
      })
  },

  getPostList () {
    return post.getPostList()
      .then(({data}) => {
        return data.payload.data
      })
  },

  deletePost ({commit}, postId) {
    return post.deletePost(postId)
      .then(() => {
        commit('DELETE_POST', postId)
      })
  }
}
