
import axios from '../http/axios'
import {generateParams} from '../helpers/helper'

export default {
  async getTransactionVaultDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)
    return await axios.get('transaction-vault/datatable', {
      params: serverQueryObj
    })
  },
  async archiveTransaction (transaction_vault_id) {
    return axios.post('transaction-vault/archive', {
      transaction_vault_id
    })
  },
  async undoTransaction (transaction_vault_id) {
    return axios.post('transaction-vault/undo', {
      transaction_vault_id
    })
  },
  async processTransaction (transaction_vault_id, merchant_id) {
    return axios.post('transaction-vault/process', {
      transaction_vault_id,
      merchant_id
    })
  },
    async processBulkTransaction (transaction_vault_ids, merchant_id) {
        return axios.post('transaction-vault/process-bulk', {
            transaction_vault_ids,
            merchant_id
        })
    },
  async undoTransactionJob (payload) {
    return axios.post('transaction-vault/undo-job', payload)
  },
    async undoBulkTransactionJobs (payload) {
    return axios.post('transaction-vault/undo-process-bulk-jobs', payload)
  },
    async archiveBulkTransaction (transaction_vault_ids) {
        return axios.post('transaction-vault/archive-bulk', {
            transaction_vault_ids
        })
    },
}
