
import transactionVault from '../../services/TransactionVaultService'

export default {
  getTransactionVaultDatatable ({commit}, {eventForServer, filter}) {
    return transactionVault.getTransactionVaultDatatable(eventForServer, filter)
      .then(({data}) => {
        if (filter.toDownload) {
          // if we are calling api for export then we will not update the state
          return data
        }
        commit('SET_TRANSACTION_VAULT', data.payload)
        return data
      })
  },
  archiveTransaction ({commit}, {transaction_vault_id}) {
    return transactionVault.archiveTransaction(transaction_vault_id)
      .then(({data}) => {

        commit('UPDATE_TRANSACTION_VAULT', data.payload)
        return data
      })
  },
  undoTransaction ({commit}, {transaction_vault_id}) {
    return transactionVault.undoTransaction(transaction_vault_id)
      .then(({data}) => {

        // commit('UPDATE_TRANSACTION_VAULT', data.payload)
        return data
      })
  },
  processTransaction ({commit}, {transaction_vault_id, merchant_id}) {
    return transactionVault.processTransaction(transaction_vault_id, merchant_id)
      .then(({data}) => {

        commit('UPDATE_TRANSACTION_VAULT', data.payload)
        console.log("Errror ", data)
        return data
      })
  },
    processBulkTransaction ({commit}, {transaction_vault_ids, merchant_id}) {
        return transactionVault.processBulkTransaction(transaction_vault_ids, merchant_id)
            .then(({data}) => {
                return data
            })
    },
  undoTransactionJob ({commit}, payload) {
    return transactionVault.undoTransactionJob(payload)
      .then(({data}) => {
        return data
      })
  },
    undoBulkTransactionJobs ({commit}, payload) {
        return transactionVault.undoBulkTransactionJobs(payload)
            .then(({data}) => {
                return data
            })
    },
    archiveBulkTransaction ({commit}, {transaction_vault_ids}) {
        return transactionVault.archiveBulkTransaction(transaction_vault_ids)
            .then(({data}) => {
                return data
            })
    },
}
