
import actions from './moduleAppConfigActions'
import getters from './moduleAppConfigGetters'
import mutations from './moduleAppConfigMutations'
import state from './moduleAppConfigState'

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations
}

