export default {
  SET_PAYMENT_REQUEST (state, paymentRequests) {
    state.paymentRequests = paymentRequests
  },

  DELETE_PAYMENT_REQUEST (state, withdrawId) {
    state.paymentRequests.data = state.paymentRequests.data.filter((s) => (s.withdraw_id != withdrawId))
    state.paymentRequests.meta.total = state.paymentRequests.meta.total - 1
  },

  CLEAR_PAYMENT_REQUEST (state) {
    state.paymentRequests.data = []
    state.paymentRequests.links = {}
    state.paymentRequests.meta = {}
  },

  SET_PENDING_PAYMENT_COUNT (state,pendingCount) {
    state.pendingCount = pendingCount
  },

  DECREASE_PENDING_PAYMENT_COUNT(state) {
    state.pendingCount = state.pendingCount - 1;
  }

}
