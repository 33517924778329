import { render, staticRenderFns } from "./VxInputGroup.vue?vue&type=template&id=5332bf05&functional=true"
import script from "./VxInputGroup.vue?vue&type=script&lang=js"
export * from "./VxInputGroup.vue?vue&type=script&lang=js"
import style0 from "./VxInputGroup.vue?vue&type=style&index=0&id=5332bf05&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\Work\\tuck\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5332bf05')) {
      api.createRecord('5332bf05', component.options)
    } else {
      api.rerender('5332bf05', component.options)
    }
    module.hot.accept("./VxInputGroup.vue?vue&type=template&id=5332bf05&functional=true", function () {
      api.rerender('5332bf05', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/src/components/vx-input-group/VxInputGroup.vue"
export default component.exports